import { styled } from '@material-ui/core/styles'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'

const BreadCrumbs = styled(Breadcrumbs)(({ theme, props }) => {
  const linkColor = (props && props.onDarkBackground)
    ? theme.palette.grey.light
    : theme.palette.grey.dark
  return {
    textTransform: 'uppercase',
    fontSize: '12px',
    fontWeight: 'bold',
    letterSpacing: 1,
    '& a': {
      textDecoration: 'none',
      color: linkColor,
    },
    '& .MuiBreadcrumbs-separator': {
      color: linkColor,
    },
  }
})

export default BreadCrumbs
