import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    position: 'absolute',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.secondary.dark,
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      width: '100%',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '3.125rem',
    paddingBottom: '3.125rem',
    flex: '0 0 60%',
    gap: '4rem',
    [theme.breakpoints.down('sm')]: {
      gap: '4rem',
    },
    [theme.breakpoints.down('xs')]: {
      gap: 0,
      flexDirection: 'column',
      alignItems: 'stretch',
    },
  },
  headline: {
    marginBottom: '.875rem',
  },
  headlineContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    paddingLeft: theme.spacing(4),
    [theme.breakpoints.only('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(3),
      paddingLeft: 0,
    },
  },
  ctaWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: '0 0 40%',
    '&:before': {
      content: '""',
      height: '100%',
      width: '1px',
      backgroundColor: theme.palette.grey.light,
      opacity: '0.5',
      position: 'absolute',
      left: '-2rem',
    },
  },
  divider: {
    backgroundColor: theme.palette.grey.light,
    opacity: '0.5',
    height: '100%',
    alignItems: 'flex-end',
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      width: '100%',
      height: '1px',
    },
  },
  ctaDiv: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  ctaContainer: {
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
      width: '100%',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  icon: {
    marginTop: '4px',
    height: '32px',
    width: '32px',
    [theme.breakpoints.down('md')]: {
      marginTop: '2px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '25px',
      width: '25px',
    },
    '&>svg>g>circle': {
      fill: theme.palette.gold.main,
    },
  },
  left: {
    marginRight: '1.25rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0.25rem',
    },
  },
  linkContainer: {
    color: theme.palette.primary.contrastText,
    display: 'block',
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.primary.contrastText,
    },
  },
  ctaTitle: {
    '&:hover': {
      cursor: 'pointer',
    },
    '&>span>svg': {
      color: theme.palette.gold.main,
    },
  },
}))

export default useStyles
