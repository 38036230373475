import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    position: 'absolute',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.secondary.dark,
    paddingLeft: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      width: '100%',
      position: 'relative',
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      height: 'auto',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '3.125rem',
    paddingBottom: '3.125rem',
    gap: '2.5rem',
    [theme.breakpoints.down('sm')]: {
      gap: '2.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      gap: 0,
      flexDirection: 'column',
      alignItems: 'stretch',
    },
  },
  headlineContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      paddingBottom: theme.spacing(4),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(3),
    },
  },
  headline: {
    color: theme.palette.primary.contrastText,
    '&:hover': {
      color: theme.palette.primary.contrastText,
    },
  },
  ctaContainer: {
    width: '100%',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  ctaButton: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(2),
    },
  },
  ctaLink: {
    color: theme.palette.primary.contrastText,
    '&:hover': {
      color: theme.palette.primary.contrastText,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0',
    },
  },
  breadcrumbs: {
    marginBottom: theme.spacing(3),
    color: 'white',
    '&>ol>li': {
      color: 'white !important',
      '&:not(:last-child):hover': {
        textDecoration: 'underline !important',
      },
      '&>a': {
        color: 'white',
        fontSize: '0.875rem',
      },
    },
  },
}))

export default useStyles
