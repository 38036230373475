import defaults from '@config/defaults'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    position: 'relative',
    marginBottom: ({ offset }) => `${offset}px`,
  },
  imageContainer: ({ images }) => ({
    backgroundImage: images && `url(${images.lg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    height: `calc(100vh - ${defaults.redesignHeaderHeight * 2}px)`,
    [theme.breakpoints.down('xs')]: {
      backgroundImage: images && `url(${images.sm})`,
    },
  }),
  ctaContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: ({ offset }) => `-${offset}px`,
    [theme.breakpoints.up('md')]: {
      bottom: ({ offset }) => `${offset}px`,
      display: 'flex',
      justifyContent: 'center',
    },
  },
}))
