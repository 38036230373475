import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import PropTypes from 'prop-types'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import ButtonCta from '@components/atoms/button-cta'
import Breadcrumbs from '@components/breadcrumbs'
import { forwardRef } from 'react'
import Link from '@components/link/index'
import NextLink from 'next/link'
import useStyles from './hero-slim-wrapper.styles'

const HeroSlimWrapper = forwardRef((props, ref) => {
  const {
    hero,
    secondaryCtaText,
    secondaryCtaLink,
  } = props

  const {
    ctaText: heroCtaText,
    summary,
    headline,
    breadcrumbs,
  } = hero

  const hasSecondaryCta = !!secondaryCtaText && !!secondaryCtaLink
  const linkProps = hero?.reference
  || (hero?.externalLink ? { externalLink: hero.externalLink } : null)

  const styles = useStyles()
  const isMobile = useMediaQuery(useTheme().breakpoints.down('xs'))
  return (
    <Box ref={ref} className={styles.root}>
      <Container>
        <Box py={3} className={styles.container}>
          <Box className={styles.headlineContainer}>
            {breadcrumbs?.length > 0
            && (
              <Breadcrumbs component={Breadcrumbs} className={styles.breadcrumbs}>
                {breadcrumbs.map((item) => {
                  return <NextLink key={item.buttonLabel} href={item.buttonLink || '#'}>{item.buttonLabel}</NextLink>
                })}
              </Breadcrumbs>
            )}
            <Link {...linkProps}>
              <Typography variant="h2" className={styles.headline}>{headline}</Typography>
            </Link>
            {!isMobile && <Typography variant="body1">{summary}</Typography>}
          </Box>
          <Box className={styles.ctaContainer}>
            <ButtonCta
              className={styles.ctaButton}
              variant="outlined"
              color="primary"
              {...hero}
              dataAttrs={{ 'aria-label': `Read more about ${headline}` }}
            >
              {heroCtaText}
            </ButtonCta>
            {hasSecondaryCta && (
              <ButtonCta
                className={styles.ctaLink}
                variant="text"
                arrow
                href={secondaryCtaLink}
              >
                {secondaryCtaText}
              </ButtonCta>
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  )
})

HeroSlimWrapper.propTypes = {
  hero: PropTypes.shape({
    headline: PropTypes.string,
    summary: PropTypes.string,
    ctaText: PropTypes.string,
    externalLink: PropTypes.string,
    reference: PropTypes.shape({
      __typename: PropTypes.string,
      slug: PropTypes.string,
    }),
    breadcrumbs: PropTypes.array,
  }),
  secondaryCtaText: PropTypes.string,
  secondaryCtaLink: PropTypes.string,
}

HeroSlimWrapper.defaultProps = {

}

export default HeroSlimWrapper
