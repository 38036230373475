import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import PropTypes from 'prop-types'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { forwardRef } from 'react'
import FeaturedCta from './featured-cta'
import useStyles from './featured-cta-wrapper.styles'


const FeaturedCtaWrapper = forwardRef((props, ref) => {
  const {
    hero,
    ctas,
  } = props

  const styles = useStyles()
  const isMobile = useMediaQuery(useTheme().breakpoints.down('xs'))

  return (
    <Box ref={ref} className={styles.root}>
      <Container>
        <Box py={3} className={styles.container}>
          <Box className={styles.headlineContainer}>
            <Typography variant="h2" className={styles.headline}>{hero.headline}</Typography>
            {!isMobile && <Typography variant="body1">{hero.summary}</Typography>}
          </Box>
          <Box className={styles.ctaWrapper}>
            {ctas.items.map((cta, i) => {
              return (
                <div key={cta.sys.id} className={styles.ctaDiv}>
                  <FeaturedCta {...cta} />
                  {
                    (i !== (ctas.items.length - 1)) && <Divider orientation="vertical" className={styles.divider} />
                  }
                </div>
              )
            })
            }
          </Box>
        </Box>
      </Container>

    </Box>
  )
})

FeaturedCtaWrapper.propTypes = {
  hero: PropTypes.object,
  ctas: PropTypes.object,
}

FeaturedCtaWrapper.defaultProps = {

}

export default FeaturedCtaWrapper
