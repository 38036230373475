import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import ErrorBoundary from '@components/error/boundary'
import { CtaText } from '@components/atoms/cta-arrow'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import Image from '@components/atoms/image'
import Link from '@components/link'
import useStyles from './featured-cta-wrapper.styles'

const FeaturedCta = (props) => {
  const {
    headline,
    summary,
    image,
    externalLink,
    reference,
  } = props
  const styles = useStyles()
  const isMobile = useMediaQuery(useTheme().breakpoints.down('xs'))
  const linkProps = externalLink ? { externalLink } : { ...reference }

  return (
    <ErrorBoundary>
      <Box className={styles.ctaContainer}>
        <Box className={styles.left}>
          <Image src={image.url} className={styles.icon} />
        </Box>
        <Box className={styles.right}>
          <Link {...linkProps} className={styles.linkContainer}>
            <CtaText variant="h4" className={styles.ctaTitle}>{headline}</CtaText>
          </Link>
          {!isMobile && <Typography variant="body2" className={styles.ctaDescription}>{summary}</Typography>}
        </Box>
      </Box>
    </ErrorBoundary>
  )
}

FeaturedCta.propTypes = {
  headline: PropTypes.string,
  summary: PropTypes.string,
  externalLink: PropTypes.string,
  image: PropTypes.object,
  reference: PropTypes.object,
}

export default FeaturedCta
