import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import FeaturedCtaWrapper from '@components/featured-cta-wrapper/featured-cta-wrapper'
import HeroSlimWrapper from '@components/hero-slim-wrapper'
import { useEffect, useState } from 'react'
import useResizeObserver from '@hooks/useResizeObserver'
import { useStyles } from './hero.styles'
import { DateTime } from 'luxon'
import { gtmEvent } from '@lib/gtm-utils'

const Hero = (props) => {
  const {
    hero, ctas, secondaryCtaText, secondaryCtaLink,
  } = props
  const [ref, entry] = useResizeObserver()
  const [offset, setOffset] = useState(0)

  const publishedDate = hero?.reference?.sys?.publishedAt

  const images = {
    sm: hero.imageMobile
      ? `${hero.imageMobile.url}?fm=jpg&fl=progressive&q=80`
      : '',
    lg: hero.image
      ? `${hero.image.url}?fm=jpg&h=1200&w=1800&fit=fill&fl=progressive&q=80`
      : '',
  }

  const styles = useStyles({ images, offset })
  useEffect(() => {
    if (entry?.contentRect?.height) {
      setOffset(entry.contentRect.height / 2)
    }
  }, [entry?.contentRect?.height])

  return (
    <Box
      className={styles.root}
      display="flex"
      flexDirection="column"
      data-cy="home-page-hero"
    >
      <Box className={styles.imageContainer} />
      <Box className={styles.ctaContainer} onClick={() => gtmEvent({
        event: 'click-header-story',
        attributes: {
          title: hero?.headline || null,
          category: hero?.reference?.cat?.category || null,
          story_author: hero?.reference?.author || null,
          publish_date: publishedDate ? DateTime.fromISO(publishedDate).toFormat('yyyy-LL-dd') : null,
        }
      })}>
        {ctas?.items?.length > 0 ? (
          <FeaturedCtaWrapper ref={ref} {...props} />
        ) : (
          <HeroSlimWrapper
            ref={ref}
            {...props}
            secondaryCtaText={secondaryCtaText}
            secondaryCtaLink={secondaryCtaLink}
          />
        )}
      </Box>
    </Box>
  )
}

Hero.propTypes = {
  hero: PropTypes.object,
  ctas: PropTypes.object,
  secondaryCtaText: PropTypes.string,
  secondaryCtaLink: PropTypes.string,
}

Hero.defaultProps = {}

export default Hero
